import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { toggleLoader } from '../../../../shared/helpers/functions-helper.service';
import { LoaderService } from './../../../../shared/services/loader.service';
import { CustomerCollectionInterface } from '../../interfaces/dashboard-collection-interface';
import { DashboardCollectionsService } from '../../services/dashboard-collections.service';
import { ModalServiceAlert } from '../../../../shared/components/modal-alert/service/modal-alert.service';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
      selector: 'app-alerts-today-customer',
      templateUrl: './alerts-today-customer.component.html',
      styleUrls: ['./alerts-today-customer.component.scss'],
      standalone: true,
      imports: [CommonModule]
})
export class AlertsTodayCustomerComponent implements OnInit, OnDestroy {
      @Input() customers!: CustomerCollectionInterface;
      dataInfo: any = [];
      selectedTag: any = null;
      private subscriptions: Subscription[] = [];

      constructor(
            private collection_service: DashboardCollectionsService,
            private loaderService: LoaderService,
            private renderer: Renderer2,
            private alertService: ModalServiceAlert
      ) { }

      /**
       * Inicializa el componente obteniendo las etiquetas del cliente
       * @returns void
       */
      ngOnInit(): void {
            const tagsSub = this.collection_service.getTagsByCustomerId(this.customers.id).subscribe((response) => {
                  this.dataInfo = response.data;
            });
            this.subscriptions.push(tagsSub);
      }

      /**
       * Limpia las suscripciones al destruir el componente
       * @returns void
       */
      ngOnDestroy(): void {
            this.subscriptions.forEach(sub => sub.unsubscribe());
      }

      /**
       * Elimina una etiqueta específica del cliente
       * @param tag_id - ID de la etiqueta a eliminar
       * @returns void
       */
      deleteTagForCustomer(tag_id: number): void {
            toggleLoader(this.loaderService, true);
            const deleteSub = this.collection_service.deleteTagForCustomer(this.customers.id, tag_id).subscribe((response) => {
                  toggleLoader(this.loaderService, false);
                  this.dataInfo = response.data;
                  const backdrop = document.querySelector('.modal-backdrop.fade.show');
                  if (backdrop) {
                        this.renderer.removeChild(document.body, backdrop);
                  }
                  this.selectedTag = null;
                  this.alertService.openModal('Éxito', 'Se ha eliminado correctamente', 'success');
            });
            this.subscriptions.push(deleteSub);
      }
}
