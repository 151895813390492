<div class="accordion-body">
      @if(customers) {
      <div class="row">
            <div class="col-6 pb-3">
                  <span>Total aprobado</span>
            </div>
            <div class="col-6 pb-3">
                  <span>{{customers.total_approved_quota | currency : '$' : 'symbol' : '1.0-0'}}</span>
            </div>

            <div class="col-6 pb-3">
                  <span>Total disponible</span>
            </div>
            <div class="col-6 pb-3">
                  <span>{{customers.total_available_quota | currency : '$' : 'symbol' : '1.0-0'}}</span>
            </div>

            <div class="col-6 pb-3">
                  <span>Valor pendiente</span>
            </div>
            <div class="col-6 pb-3">
                  <span>{{customers.credits_sum_value_pending | currency : '$' : 'symbol' : '1.0-0'}}</span>
            </div>

            <div class="col-6 pb-3">
                  <span>Dias mora</span>
            </div>
            <div class="col-6 pb-3">
                  <span>{{customers.max_days_late_credit}}</span>
            </div>

            <div class="col-6 pb-3">
                  <span>Valor intereses</span>
            </div>
            <div class="col-6 pb-3">
                  <span>{{customers.credits_sum_interest_value_calculate | currency : '$' : 'symbol' : '1.0-0'}}</span>
            </div>

            <div class="col-6 pb-3">
                  <span>Valor corrientes</span>
            </div>
            <div class="col-6 pb-3">
                  <span>{{customers.credits_sum_current_value_calculate | currency : '$' : 'symbol' : '1.0-0'}}</span>
            </div>

            <div class="col-6 pb-3">
                  <span>Valor total</span>
            </div>
            <div class="col-6 pb-3">
                  <span>{{customers.credits_sum_total_balance | currency : '$' : 'symbol' : '1.0-0'}}</span>
            </div>
      </div>
      }
</div>
