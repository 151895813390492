import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ModalServiceAlert } from '../../../../shared/components/modal-alert/service/modal-alert.service';
import { toggleLoader } from '../../../../shared/helpers/functions-helper.service';
import { LoaderService } from '../../../../shared/services/loader.service';
import { PaymentsTotalData } from '../../interfaces/dashboard-collection-payment-totals-interface';
import { DashboardCollectionsService } from '../../services/dashboard-collections.service';
import { Subscription } from 'rxjs';

@Component({
      selector: 'app-dashboard-collections-payment-totals',
      templateUrl: './dashboard-collections-payment-totals.component.html',
      styleUrls: ['./dashboard-collections-payment-totals.component.scss'],
      standalone: true,
      imports: [CommonModule]
})
export class DashboardCollectionsPaymentTotalsComponent implements OnInit, OnDestroy {
      @Input() customerId!: number;
      paymentsTotal!: PaymentsTotalData[];
      paymentSelected!: PaymentsTotalData;
      private subscriptions: Subscription[] = [];

      constructor(
            private dashboardCollectionsService: DashboardCollectionsService,
            private loaderService: LoaderService,
            private alertService: ModalServiceAlert
      ) { }

      /**
       * Inicializa el componente cargando los pagos totales del cliente
       */
      ngOnInit(): void {
            this.getAllPaymentsCustomerService();
      }

      /**
       * Limpia las suscripciones al destruir el componente
       */
      ngOnDestroy(): void {
            this.subscriptions.forEach(sub => sub.unsubscribe());
      }

      /**
       * Obtiene todos los pagos asociados al cliente desde el servicio
       * Muestra un loader mientras se realiza la petición
       * Maneja los errores mostrando alertas al usuario
       */
      getAllPaymentsCustomerService(): void {
            toggleLoader(this.loaderService, true);

            const paymentsSub = this.dashboardCollectionsService.getAllPaymentsCustomer(this.customerId).subscribe({
                  next: (resp) => {
                        toggleLoader(this.loaderService, false);
                        this.paymentsTotal = resp.data;
                  },
                  error: (error) => {
                        toggleLoader(this.loaderService, false);
                        this.alertService.openModal('Error', 'Hubo un error en la solicitud', 'error');
                  }
            });

            this.subscriptions.push(paymentsSub);
      }

      /**
       * Muestra un modal con los detalles del pago seleccionado
       * @param item - Datos del pago a mostrar
       */
      mostrarModal(item: PaymentsTotalData): void {
            this.paymentSelected = item;
      }
}
