import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DefaultIfEmptyPipe } from '../../../../shared/pipes/defaultEmpty.pipe';
import { DashboardCollectionAllCreditCustomer } from '../../interfaces/dashboard-collection-all-credits';
import { DashboardCollectionsService } from '../../services/dashboard-collections.service';
import { LoaderService } from '../../../../shared/services/loader.service';
import { ModalServiceAlert } from '../../../../shared/components/modal-alert/service/modal-alert.service';
import { toggleLoader } from '../../../../shared/helpers/functions-helper.service';
import { Subscription } from 'rxjs';

@Component({
      selector: 'app-credits-all-customer',
      templateUrl: './credits-all-customer.component.html',
      styleUrls: ['./credits-all-customer.component.scss'],
      standalone: true,
      imports: [CommonModule, DefaultIfEmptyPipe]
})
export class CreditsAllCustomerComponent implements OnInit, OnDestroy {
      @Input() customerId!: number;
      credits!: DashboardCollectionAllCreditCustomer[];
      creditSelected!: DashboardCollectionAllCreditCustomer;
      private subscriptions: Subscription[] = [];

      constructor(
            private dashboardCollectionsService: DashboardCollectionsService,
            private loaderService: LoaderService,
            private alertService: ModalServiceAlert
      ) { }

      /**
       * Inicializa el componente obteniendo todos los créditos del cliente
       * @returns void
       */
      ngOnInit(): void {
            this.getAllCreditsCustomer();
      }

      /**
       * Limpia las suscripciones al destruir el componente
       * @returns void
       */
      ngOnDestroy(): void {
            this.subscriptions.forEach(sub => sub.unsubscribe());
      }

      /**
       * Obtiene todos los créditos asociados al cliente
       * Muestra un loader mientras se realiza la petición
       * Maneja los errores mostrando alertas al usuario
       * @returns void
       */
      getAllCreditsCustomer(): void {
            const messageError = 'Error al obtener todos los créditos del cliente';

            toggleLoader(this.loaderService, true);

            const creditsSub = this.dashboardCollectionsService.getAllCreditsCustomer(this.customerId).subscribe({
                  next: (resp) => {
                        toggleLoader(this.loaderService, false);

                        if (resp.ok) {
                              this.credits = resp.data;
                        } else {
                              this.alertService.openModal('Error', resp.message || messageError, 'error');
                        }
                  },
                  error: (error) => {
                        toggleLoader(this.loaderService, false);
                        this.alertService.openModal('Error', error.error.message || messageError, 'error');
                        console.error(error);
                  }
            });

            this.subscriptions.push(creditsSub);
      }

      /**
       * Establece el crédito seleccionado para mostrar en el modal
       * @param item - Crédito seleccionado del cliente
       * @returns void
       */
      mostrarModal(item: DashboardCollectionAllCreditCustomer): void {
            this.creditSelected = item;
      }
}
