<h2 class="accordion-header" id="flush-headingEight">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
            <i class="fas fa-credit-card"></i>&nbsp; Pagos totales
      </button>
</h2>

<div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight"
      data-bs-parent="#accordionFlushExample">
      <div class="accordion-body p-0">
            <div class="row">
                  <div class="col-12"></div>
                  <div class="col-12 table-responsive">
                        <table class="table table-hover">
                              <thead class="table-light">
                                    <tr>
                                          <th>Valor Total</th>
                                          <th>Fecha de pago</th>
                                          <th></th>
                                    </tr>
                              </thead>
                              <tbody>
                                    @if (paymentsTotal && paymentsTotal.length > 0) {
                                    @for (item of paymentsTotal; track item) {
                                    <tr tabindex="-1" data-bs-toggle="modal" data-bs-target="#exampleModalPayment"
                                          (click)="mostrarModal(item)">
                                          <td>{{item.payment_major.value | currency : '$' : 'symbol' : '1.0-0'}}</td>
                                          <td>{{ item.payment_major.date }}<br /></td>
                                          <td><i class="fas fa-chevron-right"></i></td>
                                    </tr>
                                    }
                                    } @else {
                                    <tr>
                                          <td colspan="10">
                                    <tr class="text-center">
                                          <td colspan="100%" scope="row">
                                                <i class="fas fa-info-circle"></i> No se encontraron registros.
                                          </td>
                                    </tr>
                                    </td>
                                    </tr>
                                    }
                              </tbody>
                        </table>
                  </div>
            </div>
      </div>
</div>

<div class="modal fade" id="exampleModalPayment" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
            <div class="modal-content">
                  <div class="modal-header">
                        <h2 class="modal-title fs-5" id="exampleModalLabel">Detalle de pagos aplicados</h2>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  @if (paymentSelected) {
                  <div class="modal-body">
                        <div class="row">
                              <div class="col-12">
                                    <h1>Valor pagado</h1>
                              </div>
                        </div>
                        <div class="row">
                              <div class="col-md-6">
                                    <p class="mb-1"><Strong>Pago Total</Strong></p>
                                    <p class="mb-1">{{paymentSelected.payment_major.value | currency : '$' : 'symbol' :
                                          '1.0-0'}}</p>
                              </div>
                              <div class="col-md-6">
                                    <p class="mb-1"><Strong>Fecha de pago</Strong></p>
                                    <p class="mb-1">{{ paymentSelected.payment_major.date }}</p>
                              </div>
                        </div>

                        <div class="row">
                              <div class="col-12">
                                    <h2>Creditos pagados</h2>
                              </div>
                        </div>

                        <table class="table table-hover">
                              <thead class="table-light">
                                    <tr>
                                          <th>Credito id</th>
                                          <th>Obligación</th>
                                          <th># Orden</th>
                                          <th>Valor cuota</th>
                                          <th>Estado</th>
                                          <th>Total a capital</th>
                                          <th>Total a interes</th>
                                          <th>Total a corriente</th>
                                          <th>Fecha de pago</th>
                                    </tr>
                              </thead>
                              <tbody>
                                    @if (paymentSelected.payments && paymentSelected.payments.length > 0) {
                                    @for (item of paymentSelected.payments; track item) {
                                    <tr tabindex="-1" data-bs-toggle="modal" data-bs-target="#exampleModalPayment">
                                          <td>{{ item.credit_id }}</td>
                                          <td>{{ item.code_pay }}</td>
                                          <td>{{ item.order_number }}<br /></td>
                                          <td>{{ item.quota_value | currency : '$' : 'symbol' : '1.0-0' }}<br /></td>
                                          <td>{{ item.state_pay_shop }}<br /></td>
                                          <td>{{ item.total_paid_capital | currency : '$' : 'symbol' : '1.0-0' }}<br />
                                          </td>
                                          <td>{{ item.total_paid_interes | currency : '$' : 'symbol' : '1.0-0' }}<br />
                                          </td>
                                          <td>{{ item.total_paid_corriente | currency : '$' : 'symbol' : '1.0-0'
                                                }}<br /></td>
                                          <td>{{ item.date }}<br /></td>
                                    </tr>
                                    }
                                    } @else {
                                    <tr>
                                          <td colspan="10">
                                    <tr class="text-center">
                                          <td colspan="100%" scope="row">
                                                <i class="fas fa-info-circle"></i> No se encontraron registros.
                                          </td>
                                    </tr>
                                    </td>
                                    </tr>
                                    }
                              </tbody>
                        </table>
                  </div>
                  }
                  <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  </div>
            </div>
      </div>
</div>
