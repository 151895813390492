import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, debounceTime, switchMap } from 'rxjs';
import { getInitials } from '../../../../shared/helpers/functions-helper.service';
import { LoaderService } from '../../../../shared/services/loader.service';
import {
      DashboardCollectionSearchResultInterface,
      DataResultsSearchCustomer,
} from '../../interfaces/dashboard-collection-search-result-interface';
import { DashboardCollectionsService } from '../../services/dashboard-collections.service';
import { DashboardComunicationComponentService } from '../../services/dashboardComunicationsComponente.service';
import { RecentlySeenComponent } from '../recently-seen/recently-seen.component';

@Component({
      selector: 'app-dashboard-collections-search-results',
      templateUrl: './dashboard-collections-search-results.component.html',
      styleUrls: ['./dashboard-collections-search-results.component.scss'],
      standalone: true,
      imports: [CommonModule, RecentlySeenComponent]
})
export class DashboardCollectionsSearchResultsComponent implements OnInit {
      searchTerms = new Subject<string>();
      resultSearchCustomer: DataResultsSearchCustomer[] = [];
      getInitials = getInitials;

      constructor(
            private comunicationColletionServices: DashboardComunicationComponentService,
            private dashboardCollectionsService: DashboardCollectionsService,
            private loaderService: LoaderService,
            private router: Router
      ) {
            this.initializeSearchSubscription();
      }

      /**
       * Inicializa el componente y se suscribe a los cambios de búsqueda
       */
      ngOnInit(): void {
            this.subscribeToSearchChanges();
      }

      /**
       * Inicializa la suscripción de búsqueda con un debounce time y manejo de respuesta
       */
      initializeSearchSubscription(): void {
            this.searchTerms
                  .pipe(
                        debounceTime(300),
                        switchMap((term) => this.dashboardCollectionsService.searchCustomer(term))
                  )
                  .subscribe((response) => {
                        this.handleSearchResponse(response);
                  });
      }

      /**
       * Maneja la respuesta de la búsqueda y actualiza los resultados
       * @param response - Respuesta de la búsqueda del cliente
       */
      handleSearchResponse(response: DashboardCollectionSearchResultInterface): void {
            if (response.ok) {
                  this.resultSearchCustomer = response.data;
            }
      }

      /**
       * Verifica si el término de búsqueda es válido
       * @param term - Término de búsqueda a validar
       * @returns boolean - Indica si el término es válido
       */
      isValidSearchTerm(term: string): boolean {
            return term !== '' && term.length > 5;
      }

      /**
       * Redirecciona al detalle del cliente y recarga la página
       * @param idCustomer - ID del cliente seleccionado
       */
      redirectDetalle(idCustomer: number): void {
            this.router.navigateByUrl(`/cobranza/inicio/${idCustomer}`)
                  .then(() => {
                        window.location.reload();
                  });

            this.comunicationColletionServices.newReloadServiceEvent();
            this.comunicationColletionServices.setCustomerId(String(idCustomer));
      }

      /**
       * Se suscribe a los cambios en el texto de búsqueda
       */
      subscribeToSearchChanges(): void {
            this.comunicationColletionServices.textSearchCustomer.subscribe((value) => {
                  if (this.isValidSearchTerm(value)) {
                        this.searchTerms.next(value);
                  }
            });
      }
}
