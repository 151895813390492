import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomersService } from '../../../../administrative/customers/services/customers.service';
import { TableRuesComponent } from './table-rues/table-rues.component';
import { TableTrasunionComponent } from './table-trasunion/table-trasunion.component';
import { CommonModule } from '@angular/common';
import { LoaderTrasunionComponent } from './loader-trasunion/loader-trasunion.component';

@Component({
  selector: 'app-modal-finantial',
  templateUrl: './modal-finantial.component.html',
  standalone: true,
  imports: [CommonModule, TableTrasunionComponent, TableRuesComponent, LoaderTrasunionComponent]
})
export class ModalFinantialComponent {

  @Input() public customerId: any;

  public obligacionesTotales: any[] = [];
  public scoreTable: any[] = [];
  public ruesTable: any[] = [];
  public isString: boolean = false;
  public isLoading: boolean = false;
  public isNullRue: boolean = false;

  constructor(public activeModal: NgbActiveModal,
    private customerService: CustomersService,
    private cdr: ChangeDetectorRef
  ) { }

  /**
   * Inicializa el componente y obtiene la información financiera del cliente.
   */
  ngOnInit(): void {
    this.getInfoCustomerFinantial();
  }

  /**
   * Obtiene la información financiera del cliente desde el servicio.
   * Actualiza los estados de carga y maneja la información de Transunion y RUES.
   */
  public getInfoCustomerFinantial() {
    this.isLoading = true;
    this.customerService.getInfoTransunionAndRues(this.customerId)
      .subscribe(
        (response) => {
          this.handleTransunionInfo(response.data.info_transunion);
          this.handleRuesInfo(response.data.info_rues);
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  /**
   * Extrae la información de Transunion del objeto JSON recibido.
   * @param {any} jsonObject - Objeto JSON con la información de Transunion.
   * @private
   */
  private extractInformationTransunion(jsonObject: any) {
    // Información Score
    this.scoreTable = [{
      score: jsonObject.Tercero.Score.Puntaje,
      grupo: jsonObject.Tercero.Score.Politica,
      negativeReasonCode: jsonObject.Tercero.Score.IndicadorDefault,
      positiveReasonCode: jsonObject.Tercero.Score.CodigoScore || '',
      subpoblacion: jsonObject.Tercero.Score.SubPoblacion.join(', ') || ''
    }];

    // Resumen Endeudamiento
    const resumenPrincipal = jsonObject.Tercero.Consolidado.ResumenPrincipal.Registro;
    this.obligacionesTotales = resumenPrincipal.map((obligacion: any) => ({
      tipo: obligacion.PaqueteInformacion,
      cantidad: obligacion.NumeroObligaciones,
      saldoTotal: obligacion.TotalSaldo,
      cuota: obligacion.CuotaObligacionesDia
    }));
  }

  /**
   * Extrae la información de RUES del objeto JSON recibido.
   * @param {any} jsonObject - Objeto JSON con la información de RUES.
   * @private
   */
  private extractInformationRues(jsonObject: any) {
    this.ruesTable = [{
      fechaMatricula: jsonObject.fecha_matricula,
      estadoMatricula: jsonObject.estado_matricula,
      fechaRenovacion: jsonObject.fecha_renovacion,
      ultimoAnoRenovacion: jsonObject.ultimo_ano_renovado,
    }]
  }

  /**
   * Maneja la información de Transunion, determinando si es una cadena o un objeto JSON.
   * @param {any} info_transunion - Información de Transunion a procesar.
   * @private
   */
  private handleTransunionInfo(info_transunion: any) {
    const isArray = JSON.parse(info_transunion);
    if (info_transunion === null || isArray.length === 0) {
      this.isString = true;
    } else {
      const jsonObjectTransunion = JSON.parse(info_transunion);
      this.isString = false;
      this.extractInformationTransunion(jsonObjectTransunion);
    }
  }

  /**
   * Maneja la información de RUES, determinando si es nula o un objeto JSON válido.
   * @param {any} info_rues - Información de RUES a procesar.
   * @private
   */
  private handleRuesInfo(info_rues: any) {
    const isArray = JSON.parse(info_rues);
    if (info_rues === null || isArray.length === 0) {
      this.isNullRue = true;
    } else {
      const jsonObjectRues = JSON.parse(info_rues);
      this.isNullRue = false;
      this.extractInformationRues(jsonObjectRues);
    }
  }
}
