import { CommonModule } from "@angular/common";
import { Component, Input, OnDestroy } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { AuthService } from '../../../../auth/login/services/auth.service';
import { ModalServiceAlert } from "../../../../shared/components/modal-alert/service/modal-alert.service";
import { getInitials, toggleLoader, validateCampo } from '../../../../shared/helpers/functions-helper.service';
import { LoaderService } from '../../../../shared/services/loader.service';
import { CustomerCollectionInterface } from "../../interfaces/dashboard-collection-interface";
import { DashboardCollectionsService } from "../../services/dashboard-collections.service";

@Component({
      selector: "app-detail-customer",
      templateUrl: "./detail-customer.component.html",
      styleUrls: ["./detail-customer.component.scss"],
      standalone: true,
      imports: [CommonModule, FormsModule, ReactiveFormsModule]
})
export class DetailCustomerComponent implements OnDestroy {
      @Input() customers!: CustomerCollectionInterface;

      private subscriptions: Subscription = new Subscription();
      formEditCustomer!: FormGroup;
      formEditCustomerMediaContact!: FormGroup;
      mediaContactByCustomer: any = [];
      allMediaContact: any = [];
      role: any;
      edit = false;

      getInitials = getInitials;
      validateCampo = validateCampo;

      constructor(
            private dashboardCollectionsService: DashboardCollectionsService,
            private loaderService: LoaderService,
            private _fb: FormBuilder,
            private authService: AuthService,
            private alertService: ModalServiceAlert
      ) { }

      ngOnInit(): void {
            this.initForm();
            this.formEditCustomerMediaContact = this._fb.group({
                  data: this._fb.array([])
            });
            this.role = this.authService.getUser().role_id;
      }

      ngOnDestroy(): void {
            this.subscriptions.unsubscribe();
      }

      get data() {
            return this.formEditCustomerMediaContact.get('data') as FormArray;
      }

      /**
       * Agrega los medios de contacto al formulario
       * Marca como seleccionados los que ya tiene asignados el cliente
       */
      addData(): void {
            this.allMediaContact.forEach((item: any) => {
                  const isMediaContactSelected = this.mediaContactByCustomer.new.some((mediaContact: any) =>
                        mediaContact.media_contact_id === item.id);
                  this.data.push(this._fb.control(isMediaContactSelected ? item.id : false));
            });
      }

      /**
       * Maneja el cambio en los checkboxes de medios de contacto
       * @param event - Evento del checkbox
       * @param index - Índice del control en el FormArray
       */
      onChange(event: any, index: number): void {
            if (event.target) {
                  this.data.at(index).setValue(event.target.checked ? event.target.value : false);
            }
      }

      /**
       * Inicializa los formularios y obtiene los datos necesarios del cliente
       */
      initForm(): void {
            const mediaContactSub = this.dashboardCollectionsService.getMediaContactByCustomerId(this.customers.id).subscribe(res => {
                  this.mediaContactByCustomer = res.data;
            });
            this.subscriptions.add(mediaContactSub);

            const allMediaContactSub = this.dashboardCollectionsService.allMediaContact().subscribe(res => {
                  this.allMediaContact = res.data;
                  this.addData();
            });
            this.subscriptions.add(allMediaContactSub);

            const phone2Value = this.customers.phone_2 === null ? '' : this.customers.phone_2;

            this.formEditCustomer = new FormGroup({
                  email: new FormControl(this.customers.email, [
                        Validators.required,
                        Validators.email,
                  ]),
                  phone: new FormControl(this.customers.phone, [
                        Validators.required,
                        Validators.minLength(10),
                        Validators.maxLength(10),
                  ]),
                  phone2: new FormControl(phone2Value, [
                        Validators.required,
                        Validators.minLength(10),
                        Validators.maxLength(10),
                  ]),
                  address: new FormControl(this.customers.address),
                  businness_address: new FormControl(this.customers.businness_address),
                  date_next_contact: new FormControl(""),
                  exclude_customer_automatic_messages: new FormControl(this.customers.exclude_customer_automatic_messages === 'Y')
            });

            this.formEditCustomer.disable();
      }

      /**
       * Habilita/deshabilita la edición del formulario
       */
      toggleEdit(): void {
            this.edit = !this.edit;
            this.formEditCustomer.enable();
      }

      /**
       * Actualiza la información del cliente si el formulario es válido
       * Muestra mensajes de éxito o error según corresponda
       */
      updateCustomerService(): void {
            if (this.formEditCustomer.valid) {
                  toggleLoader(this.loaderService, true, 'Actualizando datos');

                  const updateSub = this.dashboardCollectionsService.updateCustomer(this.formEditCustomer.value, this.customers.id)
                        .subscribe({
                              next: (resp) => {
                                    toggleLoader(this.loaderService, false);

                                    const modalSub = this.alertService.openModal('Éxito', 'Cliente actualizado correctamente', 'success')
                                          .subscribe((result) => {
                                                if (result) {
                                                      window.location.reload();
                                                }
                                          });
                                    this.subscriptions.add(modalSub);
                              },
                              error: (error) => {
                                    toggleLoader(this.loaderService, false);
                              }
                        });
                  this.subscriptions.add(updateSub);
            } else {
                  this.alertService.openModal('Error', 'Todos los campos son obligatorios', 'error');
            }
      }

      /**
       * Actualiza los medios de contacto del cliente
       * Recarga los datos actualizados y muestra mensajes de estado
       */
      editMediaContact(): void {
            toggleLoader(this.loaderService, true);

            const editSub = this.dashboardCollectionsService.editMediaContactByCustomerId({
                  ...this.formEditCustomerMediaContact.value,
                  customer_id: this.customers.id
            }).subscribe({
                  next: (res) => {
                        toggleLoader(this.loaderService, false);

                        if (res.ok) {
                              toggleLoader(this.loaderService, true);

                              const getMediaSub = this.dashboardCollectionsService.getMediaContactByCustomerId(this.customers.id)
                                    .subscribe(res => {
                                          this.alertService.openModal('Éxito', 'Medios de contacto actualizados correctamente', 'success')
                                          toggleLoader(this.loaderService, false);
                                          this.mediaContactByCustomer = res.data;
                                    });
                              this.subscriptions.add(getMediaSub);
                        }
                  }
            });
            this.subscriptions.add(editSub);
      }
}
