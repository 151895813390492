<div class="contenido" [class.scroll]="customer_id">
      @if (customer_id == null) {
      <app-breadcrumb class="" [title]="'Gestión ' + (customers ? customers.full_name : '')" [items]="breadcrumbItems">
      </app-breadcrumb>
      }

      <div class="container-fluid">
            <div class="row">
                  <div class="col-12 px-0">
                        @if (customers) {
                        <app-alerts-today-customer [customers]="customers">
                        </app-alerts-today-customer>
                        }
                  </div>
            </div>

            <div class="row">
                  @if (!customer_id) {
                  <div class="col-2 px-0">
                        <div class="bl-bandejaEntrada">
                              <div class="bl-bandejaEntrada--menu">
                                    <p class="mb-0 mt-1">
                                          <i class="fas fa-chart-line pe-2"></i> Resumen financiero
                                    </p>
                              </div>
                              <div class="bl-bandejaEntrada--content">
                                    <div class="bl-bandejaEntrada--internet">
                                          @if (customers) {
                                          <app-customer-information [customers]="customers">
                                          </app-customer-information>
                                          }
                                    </div>
                              </div>
                              <div class="bl-bandejaEntrada--vistoReciente">
                                    <div class="bl-vistoReciente--titulo">
                                          <span>Visto recientemente</span>
                                          <i class="fas fa-times"></i>
                                    </div>
                                    <app-recently-seen></app-recently-seen>
                              </div>
                        </div>
                  </div>
                  }

                  <div [class]="(!customer_id) ? 'col-md-6' : 'col-md-7'" class="px-0">
                        @if (customers) {
                        <div class="bl-listadoConversaciones">
                              <div class="bl-listadoConversaciones-menu">
                                    <div class="bl-listadoConversaciones--initial">
                                          <div class="bl-listadoConversaciones--initial-name">
                                                <span>{{getInitials(customers.name, customers.last_name)}}</span>
                                          </div>
                                          <div class="bl-listadoConversaciones--initial-totales">
                                                {{customers.full_name}}
                                          </div>
                                    </div>
                                    <div class="bl-listadoConversaciones--acciones">
                                          <div class="bl-listadoConversaciones--acciones-item">
                                                <i class="fas fa-pencil-alt"></i>
                                          </div>
                                          <div class="bl-listadoConversaciones--acciones-item">
                                                @if (customers.favorite) {
                                                <i class="fas fa-star" (click)="setToggleFavorite(9, false)"></i>
                                                } @else {
                                                <i class="far fa-star" (click)="setToggleFavorite(9, true)"></i>
                                                }
                                          </div>
                                          <div class="bl-listadoConversaciones--acciones-item">
                                                <i class="fas fa-expand-alt"></i>
                                          </div>
                                    </div>
                              </div>
                              <div class="bl-listadoConversaciones--content">
                                    <div class="bl-listadoConversaciones-groupMenu">
                                          <!-- <app-actions-managment></app-actions-managment> -->
                                          <div class="bl-listadoConversaciones--filtros">
                                                <div class="bl-listadoConversaciones--filtros-item">
                                                      <p>Todas <i class="fas fa-chevron-down"></i></p>
                                                </div>
                                                <div class="bl-listadoConversaciones--filtros-item">
                                                      <p>Cualquier fecha <i class="fas fa-chevron-down"></i></p>
                                                </div>
                                          </div>
                                    </div>
                                    <!-- <app-list-managment></app-list-managment> -->
                              </div>
                        </div>
                        } @else {
                        <p class="text-center">No hay información para mostrar</p>
                        }
                  </div>

                  <div [class]="(!customer_id) ? 'col-md-4' : 'col-md-5'" class="px-0">
                        @if (customer_id == null) {
                        <app-search-customer></app-search-customer>
                        }
                        @if (showSearchResults) {
                        <app-dashboard-collections-search-results></app-dashboard-collections-search-results>
                        }
                        <div class="bl-detalleCliente--informacion">
                              @if (customers) {
                              <app-detail-customer [customers]="customers"></app-detail-customer>
                              }

                              <div class="accordion accordion-flush bl-listadoConversaciones--informacion-list"
                                    id="accordionFlushExample">
                                    <div class="accordion-item">
                                          @if (customers) {
                                          <app-finalcial-information [customerId]="customers.id">
                                          </app-finalcial-information>
                                          }
                                    </div>

                                    <div class="accordion-item">
                                          @if (customers) {
                                          <app-promises-payment-history-customer [customerId]="customers.id">
                                          </app-promises-payment-history-customer>
                                          }
                                    </div>

                                    <div class="accordion-item">
                                          @if (customers) {
                                          <app-payments-historial-customer
                                                [paymentsHistorialCustomer]="paymentsHistorialCustomer">
                                          </app-payments-historial-customer>
                                          }
                                    </div>

                                    <div class="accordion-item">
                                          @if (customers) {
                                          <app-total-management-customer
                                                [totalMaganamentCustomer]="totalMaganamentCustomer">
                                          </app-total-management-customer>
                                          }
                                    </div>

                                    <div class="accordion-item">
                                          @if (customers) {
                                          <app-credits-all-customer [customerId]="customers.id">
                                          </app-credits-all-customer>
                                          }
                                    </div>

                                    <div class="accordion-item">
                                          @if (customers) {
                                          <app-dashboard-collections-payment-totals [customerId]="customers.id">
                                          </app-dashboard-collections-payment-totals>
                                          }
                                    </div>
                              </div>
                        </div>
                  </div>

                  @if (customer_id) {
                  <div class="col-12 mt-3">
                        <div class="row bl-bandejaEntrada">
                              <div class="col-6">
                                    <div class="bl-bandejaEntrada--menu">
                                          <p class="mb-0 mt-1">
                                                <i class="fas fa-chart-line pe-2"></i> Resumen financiero
                                          </p>
                                    </div>
                                    <div class="bl-bandejaEntrada--content">
                                          <div class="bl-bandejaEntrada--internet">
                                                @if (customers) {
                                                <app-customer-information [customers]="customers">
                                                </app-customer-information>
                                                }
                                          </div>
                                    </div>
                              </div>
                              <div class="col-6">
                                    <div class="bl-bandejaEntrada--vistoReciente">
                                          <div class="bl-vistoReciente--titulo">
                                                <span>Visto recientemente</span>
                                                <i class="fas fa-times"></i>
                                          </div>
                                          <app-recently-seen></app-recently-seen>
                                    </div>
                              </div>
                        </div>
                  </div>
                  }
            </div>
      </div>
</div>
