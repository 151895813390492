import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-rues',
  templateUrl: './table-rues.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class TableRuesComponent {

  @Input() public infoRues: any[] = [];

}
