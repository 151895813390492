import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CustomerCollectionPaymentCustomerHistorial } from '../../interfaces/dashboard-collection-interface';
import { DefaultIfEmptyPipe } from '../../../../shared/pipes/defaultEmpty.pipe';

@Component({
      selector: 'app-payments-historial-customer',
      templateUrl: './payments-historial-customer.component.html',
      styles: [],
      standalone: true,
      imports: [
            CommonModule,
            DefaultIfEmptyPipe,
            CurrencyPipe
      ]
})
export class PaymentsHistorialCustomerComponent {
      @Input() paymentsHistorialCustomer!: CustomerCollectionPaymentCustomerHistorial[];
}
