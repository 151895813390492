<h2 class="accordion-header" id="flush-headingSeven">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
            <i class="fas fa-credit-card"></i>&nbsp; Créditos
      </button>
</h2>
<div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven"
      data-bs-parent="#accordionFlushExample">
      <div class="accordion-body p-0">
            <div class="row">
                  <div class="col-12"></div>
                  <div class="col-12 table-responsive">
                        <table class="table table-hover">
                              <thead class="table-light">
                                    <tr>
                                          <th>Proveedor</th>
                                          <th>#</th>
                                          <th>Crédito</th>
                                          <th></th>
                                    </tr>
                              </thead>
                              <tbody>
                                    @if(credits && credits.length > 0) {
                                    @for(item of credits; track item.id) {
                                    <tr tabindex="-1" data-bs-toggle="modal" data-bs-target="#exampleModal"
                                          (click)="mostrarModal(item)">
                                          <td>{{ item.shop_commerces_name }}</td>
                                          <td>
                                                <strong>Orden:</strong> <br />
                                                {{ item.order_number | defaultIfEmpty:'Numero de orden no disponible' }}
                                                <br />
                                                <strong>Obligación:</strong> <br />
                                                {{ item.code_pay }}<br />
                                          </td>
                                          <td>
                                                <span class="badge badge-{{ item.credits_states_color }}">
                                                      {{ item.credits_states_name }}
                                                </span>
                                                <br />
                                          </td>
                                          <td>
                                                <i class="fas fa-chevron-right"></i>
                                          </td>
                                    </tr>
                                    }
                                    }
                                    @if(!(credits && credits.length > 0)) {
                                    <tr>
                                          <td colspan="10">
                                    <tr class="text-center">
                                          <td colspan="100%" scope="row"><i class="fas fa-info-circle"></i> No se
                                                encontraron registros.</td>
                                    </tr>
                                    </td>
                                    </tr>
                                    }
                              </tbody>
                        </table>
                  </div>
            </div>
      </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
            <div class="modal-content">
                  <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Detalle Crédito</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  @if(creditSelected) {
                  <div class="modal-body">
                        <div class="row">
                              <div class="col-md-6">
                                    <p class="mb-1"><Strong>Proveedor</Strong></p>
                                    <p class="mb-1">{{ creditSelected.shop_commerces_name }}</p>
                              </div>
                              <div class="col-md-6">
                                    <p class="mb-1"><Strong>orden</Strong></p>
                                    <p class="mb-1">{{ creditSelected.order_number }}</p>
                              </div>
                              <div class="col-md-6">
                                    <p class="mb-1"><Strong>Obligación</Strong></p>
                                    <p class="mb-1">{{ creditSelected.code_pay }}</p>
                              </div>
                              <div class="col-md-6">
                                    <p class="mb-1"><Strong>Fecha retiro</Strong></p>
                                    <p class="mb-1">{{ creditSelected.date_withdrawal }}</p>
                              </div>
                              <div class="col-md-6">
                                    <p class="mb-1"><Strong>Fecha entrega</Strong></p>
                                    <p class="mb-1">{{ creditSelected.delivery_date }}</p>
                              </div>
                              <div class="col-md-6">
                                    <p class="mb-1"><Strong>Fecha fin</Strong></p>
                                    <p class="mb-1">{{ creditSelected.deadline }}</p>
                              </div>
                              <div class="col-md-6">
                                    <p class="mb-1"><Strong>Valor Crédito</Strong></p>
                                    <p class="mb-1">{{ creditSelected.value_credit | currency : '$' : 'symbol' : '1.0-0'
                                          }}</p>
                              </div>
                              <div class="col-md-6">
                                    <p class="mb-1"><Strong>Abonado</Strong></p>
                                    <p class="mb-1">{{ creditSelected.number_fee }}</p>
                              </div>
                              <div class="col-md-6">
                                    <p class="mb-1"><Strong>Saldo</Strong></p>
                                    <p class="mb-1">{{ creditSelected.total_payments != null ?
                                          (creditSelected.total_payments | currency : '$' : 'symbol' : '1.0-0') : '0' }}
                                    </p>
                              </div>
                              <div class="col-md-6">
                                    <p class="mb-1"><Strong>Dias mora</Strong></p>
                                    <p class="mb-1">{{ creditSelected.days_late_credit != null ?
                                          creditSelected.days_late_credit : '0' }}</p>
                              </div>
                              <div class="col-md-6">
                                    <p class="mb-1"><Strong>V. mora</Strong></p>
                                    <p class="mb-1">{{ creditSelected.value_pending | currency : '$' : 'symbol' :
                                          '1.0-0' }}</p>
                              </div>
                              <div class="col-md-6">
                                    <p class="mb-1"><Strong>Dias corrientes</Strong></p>
                                    <p class="mb-1">{{ creditSelected.credit_payment_term_days != null ?
                                          creditSelected.credit_payment_term_days : '0' }}</p>
                              </div>
                              <div class="col-md-6">
                                    <p class="mb-1"><Strong>V. corriente</Strong></p>
                                    <p class="mb-1">{{ creditSelected.current_value_calculate != null ?
                                          (creditSelected.current_value_calculate | currency : '$' : 'symbol' : '1.0-0')
                                          : '0' }}</p>
                              </div>
                              <div class="col-md-6">
                                    <p class="mb-1"><Strong>Saldo total</Strong></p>
                                    <p class="mb-1">{{ creditSelected.total_balance != null ?
                                          (creditSelected.total_balance | currency : '$' : 'symbol' : '1.0-0') : '0' }}
                                    </p>
                              </div>
                              <div class="col-md-6">
                                    <p class="mb-1"><Strong>Pedido entregado</Strong></p>
                                    <p class="mb-1">{{ creditSelected.delivery_date }}</p>
                              </div>
                        </div>
                  </div>
                  }
                  <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  </div>
            </div>
      </div>
</div>
