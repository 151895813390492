<h2 class="accordion-header" id="flush-headingFive">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
            <i class="fas fa-money-check-alt"></i>&nbsp; Historial de pagos
      </button>
</h2>
<div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFive"
      data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
            <div class="bl-listadoConversaciones--informacion-list">
                  @if (paymentsHistorialCustomer.length > 0) {
                  <div>
                        @for (payment of paymentsHistorialCustomer; track payment) {
                        <div class="row mb-3 card-border">
                              <div class="col-12">
                                    <i class="fas fa-calendar-alt"></i>&nbsp;Fecha: {{ payment.date_payment }}
                              </div>
                              <div class="col-12">
                                    <i class="fas fa-dollar-sign"></i>&nbsp; Valor total: {{ payment.value_total_payment
                                    | currency : '$' : 'symbol' : '1.0-0' }}
                              </div>
                              <div class="col-12">
                                    <i class="fas fa-money-bill-wave"></i>&nbsp; Valor capital: {{ payment.value |
                                    currency : '$' : 'symbol' : '1.0-0' }}
                              </div>
                              <div class="col-12">
                                    <i class="fas fa-money-bill-wave"></i>&nbsp; Valor corriente: {{
                                    payment.value_current_rate | currency : '$' : 'symbol' : '1.0-0' }}
                              </div>
                              <div class="col-12">
                                    <i class="fas fa-exclamation-circle"></i>&nbsp; Valor mora: {{
                                    payment.value_interes_rate | currency : '$' : 'symbol' : '1.0-0' }}
                              </div>
                              <div class="col-12">
                                    <i class="fas fa-hashtag"></i>&nbsp; Obligación: {{ payment.credits_code_pay}}
                              </div>
                              <div class="col-12">
                                    <i class="fas fa-hashtag"></i>&nbsp; Orden: {{ payment.credits_order_number |
                                    defaultIfEmpty:'Numero de orden no disponible' }}
                              </div>
                              <div class="col-12 text-center text-white bg-{{ payment.credits_states_color }}">
                                    <i class="fas fa-chart-line"></i>&nbsp; {{ payment.credits_states_name }}
                              </div>
                        </div>
                        }
                  </div>
                  }
            </div>
            @if (paymentsHistorialCustomer.length === 0) {
            <div>
                  <div class="alert alert-warning" role="alert">
                        No hay pagos registrados.
                  </div>
            </div>
            }
      </div>
</div>
