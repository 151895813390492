import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { CustomerCollectionInterface } from '../../interfaces/dashboard-collection-interface';

@Component({
      selector: 'app-customer-information',
      templateUrl: './customer-information.component.html',
      styleUrls: ['./customer-information.component.scss'],
      standalone: true,
      imports: [CommonModule, CurrencyPipe]
})
export class CustomerInformationComponent {
      @Input() customers!: CustomerCollectionInterface;
}
