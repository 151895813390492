import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
      providedIn: 'root',
})
export class DashboardComunicationComponentService {
      visible = new BehaviorSubject(false);
      visibleSearchResult = new BehaviorSubject(false);
      textSearchCustomer = new BehaviorSubject('');
      customerId = new BehaviorSubject('');
      customer_id: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);

      private showFormMagementSubject = new BehaviorSubject<boolean>(false);
      showFormMagement$ = this.showFormMagementSubject.asObservable();

      private typeManagementSubject = new BehaviorSubject<string>('');
      typeManagement$ = this.typeManagementSubject.asObservable();

      private noteIdSelectedSubject = new BehaviorSubject<number>(0);
      noteIdSelected$ = this.noteIdSelectedSubject.asObservable();

      private selectedTabSubject = new BehaviorSubject<string>('respuesta');
      selectedTab$ = this.selectedTabSubject.asObservable();

      private isTypeWhatsapp = new BehaviorSubject<boolean>(false);
      isTypeWhatsapp$ = this.isTypeWhatsapp.asObservable();

      private infoCustomer = new BehaviorSubject<any>(null);
      infoCustomer$ = this.infoCustomer.asObservable();

      private newNoteSavedSource = new Subject<void>();
      newNoteSaved$ = this.newNoteSavedSource.asObservable();

      private newReloadServiceSource = new Subject<void>();
      newReloadService$ = this.newReloadServiceSource.asObservable();

      private detailPromisePaymentNoteSource = new Subject<void>();
      detailPromisePaymentNote$ = this.detailPromisePaymentNoteSource.asObservable();

      private formReasonConsultation = new Subject<any>();
      formReasonConsultation$ = this.formReasonConsultation.asObservable();

      private resetFormPaymentNoteSource = new Subject<void>();
      resetFormPaymentNote$ = this.resetFormPaymentNoteSource.asObservable();

      formPromsePayment: FormGroup;

      constructor(private formBuilder: FormBuilder) {
            this.formPromsePayment = this.createFormPromisePayment();
      }

      /**
       * Crea un nuevo formulario para promesa de pago
       * @returns FormGroup con los campos del formulario inicializados
       */
      private createFormPromisePayment(): FormGroup {
            return this.formBuilder.group({
                  typeContactType: new FormControl(''),
                  collection_type_contact_id: new FormControl(''),
                  collection_motive_mora_id: new FormControl(''),
                  collection_payment_mean_id: new FormControl(''),
                  date_payment: new FormControl(''),
            });
      }

      /**
       * Obtiene los valores actuales del formulario de promesa de pago
       * @returns Objeto con los valores del formulario
       */
      getFormPromisePaymentValues() {
            return this.formPromsePayment.value;
      }

      /**
       * Actualiza los valores del formulario de promesa de pago
       * @param values Valores a establecer en el formulario
       */
      setFormPromisePaymentValues(values: any) {
            this.formPromsePayment.patchValue(values);
      }

      /**
       * Reinicia el formulario de promesa de pago a sus valores iniciales
       */
      resetFormPromisePaymentValues() {
            this.formPromsePayment.reset();
      }

      /**
       * Obtiene el observable del formulario de razón de consulta
       */
      getFormReasonConsultation() {
            return this.formReasonConsultation.asObservable();
      }

      /**
       * Establece los valores del formulario de razón de consulta
       * @param values Valores a establecer
       */
      setFormReasonConsultation(values: any) {
            this.formReasonConsultation.next(values);
      }

      /**
       * Alterna la visibilidad de la nota
       */
      toggleVisibilityNote() {
            this.visible.next(!this.visible.value);
      }

      /**
       * Alterna la visibilidad del resultado de búsqueda
       */
      toggleVisibilitySearchResult() {
            this.visibleSearchResult.next(!this.visibleSearchResult.value);
      }

      /**
       * Establece el texto de búsqueda del cliente
       * @param textCustomer Texto de búsqueda
       */
      setTextSearchCustomer(textCustomer: string) {
            this.textSearchCustomer.next(textCustomer);
      }

      /**
       * Establece el ID del cliente para WhatsApp
       * @param idCustomer ID del cliente
       */
      setCustomer_Id(idCustomer: number) {
            this.customer_id.next(idCustomer);
      }

      /**
       * Obtiene el ID del cliente para WhatsApp
       * @returns Observable con el ID del cliente
       */
      getCustomer_Id(): Observable<number | null> {
            return this.customer_id.asObservable();
      }

      /**
       * Establece el ID del cliente
       * @param idCustomer ID del cliente
       */
      setCustomerId(idCustomer: string) {
            this.customerId.next(idCustomer);
      }

      /**
       * Obtiene el tipo de gestión actual
       * @returns Tipo de gestión
       */
      getTypeManagement(): string {
            return this.typeManagementSubject.value;
      }

      /**
       * Establece el tipo de gestión
       * @param newTypeManagement Nuevo tipo de gestión
       */
      setTypeManagement(newTypeManagement: string): void {
            this.typeManagementSubject.next(newTypeManagement);
      }

      /**
       * Obtiene la pestaña seleccionada actualmente
       * @returns Pestaña seleccionada
       */
      getSelectedTab(): string {
            return this.selectedTabSubject.value;
      }

      /**
       * Establece la pestaña seleccionada
       * @param newSelectedTab Nueva pestaña seleccionada
       */
      setSelectedTab(newSelectedTab: string): void {
            this.selectedTabSubject.next(newSelectedTab);
      }

      /**
       * Obtiene el estado de WhatsApp
       * @returns Estado de WhatsApp
       */
      getTypeWhatsapp(): boolean {
            return this.isTypeWhatsapp.value;
      }

      /**
       * Establece el estado de WhatsApp
       * @param newSelectedTab Nuevo estado de WhatsApp
       */
      setTypeWhatsapp(newSelectedTab: boolean): void {
            this.isTypeWhatsapp.next(newSelectedTab);
      }

      /**
       * Obtiene la información del cliente
       * @returns Información del cliente
       */
      getInfoCustomer(): any {
            return this.infoCustomer.value;
      }

      /**
       * Establece la información del cliente
       * @param newSelectedTab Nueva información del cliente
       */
      setInfoCustomer(newSelectedTab: any): void {
            this.infoCustomer.next(newSelectedTab);
      }

      /**
       * Obtiene el estado del formulario de gestión
       * @returns Estado del formulario
       */
      getShowFormMagement(): boolean {
            return this.showFormMagementSubject.value;
      }

      /**
       * Alterna el estado del formulario de gestión
       */
      setShowFormMagement(): void {
            this.showFormMagementSubject.next(!this.showFormMagementSubject.value);
      }

      /**
       * Establece el valor del formulario de gestión
       * @param value Nuevo valor
       */
      setShowFormMagementValue(value: boolean): void {
            this.showFormMagementSubject.next(value);
      }

      /**
       * Emite evento cuando se guarda una nueva nota
       */
      emitNewNoteSavedEvent() {
            this.newNoteSavedSource.next();
      }

      /**
       * Emite evento para recargar el servicio
       */
      newReloadServiceEvent() {
            this.newReloadServiceSource.next();
      }

      /**
       * Emite evento para el detalle de nota de promesa de pago
       */
      detailPromisePaymentNoteEvent() {
            this.detailPromisePaymentNoteSource.next();
      }

      /**
       * Emite evento para reiniciar el formulario de nota de pago
       */
      resetFormPaymentNoteSourceEvent() {
            this.resetFormPaymentNoteSource.next();
      }

      /**
       * Obtiene el ID de la nota seleccionada
       * @returns ID de la nota
       */
      getNoteIdSelected(): number {
            return this.noteIdSelectedSubject.value;
      }

      /**
       * Establece el ID de la nota seleccionada
       * @param newNoteId Nuevo ID de nota
       */
      setNoteIdSelected(newNoteId: number): void {
            this.noteIdSelectedSubject.next(newNoteId);
      }
}
