import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { DashboardComunicationComponentService } from '../../services/dashboardComunicationsComponente.service';

@Component({
      selector: 'app-search-customer',
      templateUrl: './search-customer.component.html',
      styleUrls: ['./search-customer.component.scss'],
      standalone: true,
      imports: [CommonModule]
})
export class SearchCustomerComponent {
      constructor(
            private comunicationColletionServices: DashboardComunicationComponentService
      ) { }

      /**
       * Actualiza el texto de búsqueda del cliente y notifica el cambio
       * @param event - Evento del input de búsqueda
       */
      searchCustomer(event: any): void {
            const text = event.target.value;
            this.comunicationColletionServices.setTextSearchCustomer(text);
      }

      /**
       * Alterna la visibilidad del componente de resultados de búsqueda
       */
      showComponentSearchResult(): void {
            this.comunicationColletionServices.toggleVisibilitySearchResult();
      }
}
