import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModalServiceAlert } from '../../../../shared/components/modal-alert/service/modal-alert.service';
import { getInitials } from '../../../../shared/helpers/functions-helper.service';
import { recentlySeenCollectionInterface } from '../../interfaces/dashboard-collection-recentlySeen-interface';
import { DashboardCollectionsService } from '../../services/dashboard-collections.service';
import { DashboardComunicationComponentService } from '../../services/dashboardComunicationsComponente.service';

@Component({
      selector: 'app-recently-seen',
      templateUrl: './recently-seen.component.html',
      styleUrls: ['./recently-seen.component.scss'],
      standalone: true,
      imports: [CommonModule]
})
export class RecentlySeenComponent implements OnInit, OnDestroy {
      customersRecentlySeen: recentlySeenCollectionInterface[] = [];
      getInitials = getInitials;
      private subscriptions: Subscription = new Subscription();

      constructor(
            private dashboardCollectionsService: DashboardCollectionsService,
            private comunicationColletionServices: DashboardComunicationComponentService,
            private router: Router,
            private alertService: ModalServiceAlert
      ) { }

      /**
       * Inicializa el componente obteniendo los clientes vistos recientemente
       */
      ngOnInit(): void {
            this.getRecentlySeenService();
      }

      /**
       * Limpia las suscripciones al destruir el componente
       */
      ngOnDestroy(): void {
            this.subscriptions.unsubscribe();
      }

      /**
       * Obtiene la lista de clientes vistos recientemente
       * Maneja los errores mostrando mensajes al usuario
       */
      getRecentlySeenService(): void {
            const subscription = this.dashboardCollectionsService.getRecentlySeen().subscribe({
                  next: (resp) => {
                        if (resp.ok) {
                              this.customersRecentlySeen = resp.data;
                        } else {
                              this.alertService.openModal('Error', 'Hubo un error al realizar la consulta', 'error');
                        }
                  },
                  error: (error) => {
                        this.alertService.openModal('Error', 'Hubo un error en la solicitud', 'error');
                        console.error(error);
                  }
            });
            this.subscriptions.add(subscription);
      }

      /**
       * Navega al detalle de un cliente específico
       * @param idCustomer - ID del cliente al que se desea navegar
       */
      redirectDetalle(idCustomer: number): void {
            this.router.navigate(['/cobranza/inicio', idCustomer]);
      }
}
