<div class="bl-detalleCliente--informacion-contenido">
      <div class="bl-detalleCliente--informacion-iniciales">
            <span>{{ getInitials(customers.name, customers.last_name) }}</span>
      </div>
      <div class="bl-detalleCliente--informacion-totales">
            <strong>{{ customers.full_name }}</strong>
      </div>
      <div class="bl-detalleCliente--informacion-acciones">
            @if (role == 1 || role == 14) {
            <i class="fas fa-pencil-alt" data-bs-toggle="modal" data-bs-target="#staticBackdrop"></i>
            }
      </div>
</div>

<div class="bl-listadoConversaciones--informacion-list container">
      <div class="row">
            <div class="col-5 pb-3">
                  <span>Id</span>
            </div>
            <div class="col-7 pb-3 ps-lg-0">
                  <span>{{ customers.id }}</span>
            </div>
      </div>
      <div class="row">
            <div class="col-5 pb-3">Nombre</div>
            <div class="col-7 pb-3 ps-lg-0">{{ customers.full_name }}</div>
      </div>
      <div class="row">
            <div class="col-5 pb-3">Cédula</div>
            <div class="col-7 pb-3 ps-lg-0">{{ customers.identification }}</div>
      </div>
      <div class="row">
            <div class="col-5 pb-3">Nit</div>
            <div class="col-7 pb-3 ps-lg-0">{{ customers.nit }}</div>
      </div>
      <div class="row">
            <div class="col-5 pb-3">Negocio</div>
            <div class="col-7 pb-3 ps-lg-0">{{ customers.buss_name }}</div>
      </div>
      <div class="row">
            <div class="col-5 pb-3">Correo</div>
            <div class="col-7 pb-3 ps-lg-0">{{ customers.email }}</div>
      </div>
      <div class="row">
            <div class="col-5 pb-3">Télefono</div>
            <div class="col-7 pb-3 ps-lg-0">{{ customers.phone }}</div>
      </div>
      <div class="row">
            <div class="col-5 pb-3">Télefono 2</div>
            <div class="col-7 pb-3 ps-lg-0">{{ customers.phone_2 }}</div>
      </div>
      <div class="row">
            <div class="col-5 pb-3">Dirección</div>
            <div class="col-7 pb-3 ps-lg-0">{{ customers.address }}</div>
      </div>
      <div class="row">
            <div class="col-5 pb-3">Dirección negocio</div>
            <div class="col-7 pb-3 ps-lg-0">{{ customers.businness_address }}</div>
      </div>
      <div class="row">
            <div class="col-5 pb-3">Cliente creado en</div>
            <div class="col-7 ps-lg-0">{{ customers.created | date:'medium' }}</div>
      </div>
      <div class="row">
            <div class="col-5 pb-3">Última modificación</div>
            <div class="col-7 pb-3 ps-lg-0">{{ customers.modified | date:'medium' }}</div>
      </div>
      <div class="row">
            <div class="col-5 pb-3">Medios de comunicación elegidos en registro</div>
            <div class="col-5">
                  @for (medioContactChoosed of mediaContactByCustomer.old; track medioContactChoosed) {
                  <div class="col-12 tickets">
                        {{ medioContactChoosed.media_contact.name }}
                  </div>
                  }
            </div>
      </div>
      <div class="row">
            <div class="col-5 pb-3">Medios de comunicación elegidos por el cliente despúes del registro</div>
            <div class="col-5">
                  @for (medioContactChoosed of mediaContactByCustomer.new; track medioContactChoosed) {
                  <div class="col-12 tickets">
                        {{ medioContactChoosed.media_contact.name }}
                  </div>
                  }
            </div>
            <div class="col-2">
                  <i class="fas fa-pencil-alt edit" data-bs-toggle="modal" data-bs-target="#editMediaContact"></i>
            </div>
      </div>
</div>

<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
            <div class="modal-content">
                  <div class="modal-header">
                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Informacion Cliente</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                        <form [formGroup]="formEditCustomer">
                              <div class="row">
                                    <div class="col-6 pb-3">
                                          <p class="mb-1">
                                                <strong>Nombre</strong>
                                          </p>
                                          <p class="mb-1">{{ customers.name }}</p>
                                    </div>
                                    <div class="col-6 pb-3">
                                          <p class="mb-1">
                                                <strong>Apellido</strong>
                                          </p>
                                          <p class="mb-1">{{ customers.last_name }}</p>
                                    </div>
                              </div>
                              <div class="row">
                                    <div class="col-12 pb-3">
                                          <p class="mb-1">
                                                <strong>Email</strong>
                                          </p>
                                          <input type="email" class="form-control" formControlName="email"
                                                [value]="customers.email" [ngModel]="customers.email" />
                                          <span class="message_error" *ngIf="validateCampo(formEditCustomer, 'email')">
                                                {{ validateCampo(formEditCustomer, 'email') }}
                                          </span>
                                    </div>
                              </div>
                              <div class="row">
                                    <div class="col-6 pb-3">
                                          <p class="mb-1">
                                                <strong>Télefono</strong>
                                          </p>
                                          <p class="mb-1">
                                                <input type="text" class="form-control" formControlName="phone"
                                                      [value]="customers.phone" />
                                                <span class="message_error"
                                                      *ngIf="validateCampo(formEditCustomer, 'phone')">
                                                      {{ validateCampo(formEditCustomer, 'phone') }}
                                                </span>
                                          </p>
                                    </div>
                                    <div class="col-6 pb-3">
                                          <p class="mb-1">
                                                <strong>Télefono 2</strong>
                                          </p>
                                          <p class="mb-1">
                                                <input type="text" class="form-control" [value]="customers.phone_2"
                                                      formControlName="phone2" />
                                                <span class="message_error"
                                                      *ngIf="validateCampo(formEditCustomer, 'phone2')">
                                                      {{ validateCampo(formEditCustomer, 'phone2') }}
                                                </span>
                                          </p>
                                    </div>
                              </div>
                              <div class="row">
                                    <div class="col-12 pb-3">
                                          <p class="mb-1">
                                                <strong>Dirección</strong>
                                          </p>
                                          <input type="text" class="form-control" [value]="customers.address"
                                                formControlName="address" />
                                          <span class="message_error"
                                                *ngIf="validateCampo(formEditCustomer, 'address')">
                                                {{ validateCampo(formEditCustomer, 'address') }}
                                          </span>
                                    </div>
                                    <div class="col-12 pb-3">
                                          <p class="mb-1">
                                                <strong>Dirección del negocio</strong>
                                          </p>
                                          <p class="mb-1">
                                                <input type="text" class="form-control"
                                                      [value]="customers.businness_address"
                                                      formControlName="businness_address" />
                                                <span class="message_error"
                                                      *ngIf="validateCampo(formEditCustomer, 'businness_address')">
                                                      {{ validateCampo(formEditCustomer, 'businness_address') }}
                                                </span>
                                          </p>
                                    </div>
                                    <div class="col-12 pb-3">
                                          <p class="mb-1">
                                                <strong>Fecha proximo contacto</strong>
                                          </p>
                                          <p class="mb-1">
                                                <input type="date" class="form-control"
                                                      [value]="customers.date_next_contact"
                                                      formControlName="date_next_contact" />
                                                <span class="message_error"
                                                      *ngIf="validateCampo(formEditCustomer, 'date_next_contact')">
                                                      {{ validateCampo(formEditCustomer, 'date_next_contact') }}
                                                </span>
                                          </p>
                                    </div>
                              </div>
                              <div class="col-12 pb-3 mb-3">
                                    <p class="mb-1">
                                          <strong>Excluir cliente de mensajes automáticos</strong>
                                    </p>
                                    <p class="mb-1">
                                          <input type="checkbox"
                                                formControlName="exclude_customer_automatic_messages" />
                                          <span class="message_error"
                                                *ngIf="validateCampo(formEditCustomer, 'exclude_customer_automatic_messages')">
                                                {{ validateCampo(formEditCustomer,
                                                'exclude_customer_automatic_messages') }}
                                          </span>
                                    </p>
                              </div>
                              <div class="row">
                                    <div class="col-6 pb-3">Cliente creado en</div>
                                    <div class="col-6 ps-lg-0">{{ customers.created | date:'medium' }}</div>
                              </div>
                              <div class="row">
                                    <div class="col-6 pb-3">Última actividad</div>
                                    <div class="col-6 pb-3 ps-lg-0">{{ customers.modified | date:'medium' }}</div>
                              </div>
                        </form>
                  </div>
                  <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        @if (edit) {
                        <button type="button" class="btn btn-primary" (click)="updateCustomerService()">Actualizar
                              datos</button>
                        } @else {
                        <button type="button" class="btn btn-primary" (click)="toggleEdit()">Editar datos</button>
                        }
                  </div>
            </div>
      </div>
</div>

<div class="modal fade" id="editMediaContact" tabindex="-1" aria-labelledby="editMediaContactLabel" aria-hidden="true">
      <div class="modal-dialog">
            <div class="modal-content">
                  <div class="modal-header">
                        <h1 class="modal-title fs-5" id="editMediaContactLabel">{{ customers.full_name }}</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                        <h4 class="mb-3">Editar medios de contacto</h4>
                        <form [formGroup]="formEditCustomerMediaContact">
                              <div formArrayName="data">
                                    @for (item of data.controls; track $index) {
                                    <label>
                                          <input type="checkbox" [value]="allMediaContact[$index].id"
                                                [checked]="item.value" (change)="onChange($event, $index)">
                                          {{ allMediaContact[$index].name }}
                                    </label>
                                    }
                              </div>
                        </form>
                  </div>
                  <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        <button (click)="editMediaContact()" type="button" class="btn btn-primary">Editar</button>
                  </div>
            </div>
      </div>
</div>
