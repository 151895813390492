import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CustomerCollectionTotalManagement } from '../../interfaces/dashboard-collection-interface';

@Component({
      selector: 'app-total-management-customer',
      templateUrl: './total-management-customer.component.html',
      standalone: true,
      styles: [],
      imports: [CommonModule]
})
export class TotalManagementCustomerComponent {
      @Input() totalMaganamentCustomer!: CustomerCollectionTotalManagement;
}
