import { Component, Input, OnDestroy } from '@angular/core';
import { DashboardCollectionsService } from '../../services/dashboard-collections.service';
import { DefaultIfEmptyPipe } from '../../../../shared/pipes/defaultEmpty.pipe';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
      selector: 'app-finalcial-information',
      templateUrl: './finalcial-information.component.html',
      styleUrls: ['./finalcial-information.component.scss'],
      standalone: true,
      imports: [CommonModule, DefaultIfEmptyPipe]
})
export class FinalcialInformationComponent implements OnDestroy {
      @Input() customerId!: number;
      credits: any = [];
      loading: boolean = false;
      selectedState: string = '';
      private subscriptions: Subscription = new Subscription();

      constructor(
            private dashboardCollectionsService: DashboardCollectionsService,
      ) { }

      /**
       * Inicializa el componente obteniendo los créditos del cliente
       */
      ngOnInit(): void {
            this.getCredits(this.selectedState);
      }

      /**
       * Limpia las suscripciones al destruir el componente
       */
      ngOnDestroy(): void {
            this.subscriptions.unsubscribe();
      }

      /**
       * Obtiene los créditos del cliente según el estado seleccionado
       * @param state - Estado de los créditos a filtrar
       * @returns void
       */
      getCredits(state: any): void {
            if (this.loading) return;
            this.selectedState = state;
            this.loading = true;
            this.credits = [];
            const subscription = this.dashboardCollectionsService.getAllCreditsCustomer(this.customerId, state).subscribe({
                  next: (response) => {
                        this.credits = response.data;
                        this.loading = false;
                  },
                  error: (error) => {
                        this.loading = false;
                  },
            });
            this.subscriptions.add(subscription);
      }
}
