import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomerCollectionPromisesPaymentsHistorialInterface } from '../../interfaces/dashboard-collection-promises-payment-historial-interface';
import { DashboardCollectionsService } from '../../services/dashboard-collections.service';
import { DashboardComunicationComponentService } from '../../services/dashboardComunicationsComponente.service';
import { LoaderService } from '../../../../shared/services/loader.service';
import { ModalServiceAlert } from '../../../../shared/components/modal-alert/service/modal-alert.service';
import { toggleLoader } from '../../../../shared/helpers/functions-helper.service';

@Component({
      selector: 'app-promises-payment-history-customer',
      templateUrl: './promises-payment-history-customer.component.html',
      styles: [],
      standalone: true,
      imports: [CommonModule]
})
export class PromisesPaymentHistoryCustomerComponent implements OnDestroy {
      @Input() customerId!: number;
      promisesPaymentsHistorial!: CustomerCollectionPromisesPaymentsHistorialInterface[];
      private subscriptions: Subscription = new Subscription();

      constructor(
            private dashboardCollectionsService: DashboardCollectionsService,
            private loaderService: LoaderService,
            private alertService: ModalServiceAlert
      ) { }

      /**
       * Inicializa el componente obteniendo el historial de promesas de pago
       */
      ngOnInit(): void {
            this.getPromisesPaymentsHistorial();
      }

      /**
       * Limpia las suscripciones al destruir el componente
       */
      ngOnDestroy(): void {
            this.subscriptions.unsubscribe();
      }

      /**
       * Obtiene el historial de promesas de pago del cliente
       * Muestra mensajes de error si la consulta falla
       */
      getPromisesPaymentsHistorial(): void {
            toggleLoader(this.loaderService, true);
            const subscription = this.dashboardCollectionsService.getPromisesPaymentsHistorial(this.customerId).subscribe({
                  next: (resp) => {
                        toggleLoader(this.loaderService, false);
                        if (resp.ok) {
                              this.promisesPaymentsHistorial = resp.data;
                        } else {
                              this.alertService.openModal('Error', 'Hubo un error al realizar la consulta', 'error');
                        }
                  },
                  error: (error) => {
                        toggleLoader(this.loaderService, false);
                        this.alertService.openModal('Error', 'Hubo un error en la solicitud', 'error');
                        console.error(error);
                  }
            });
            this.subscriptions.add(subscription);
      }

      /**
       * Actualiza el cumplimiento de una promesa de pago
       * @param compliment - Estado de cumplimiento de la promesa
       * @param id - Identificador de la promesa de pago
       * @param reason - Razón del cambio de estado
       */
      updatePromisePaymentCompliment(compliment: string, id: number, reason: string): void {
            const messageError = "Error al actualizar la promesa de pago";
            const subscription = this.dashboardCollectionsService.updatePromisePaymentCompliment(id, compliment, reason).subscribe({
                  next: (resp) => {
                        toggleLoader(this.loaderService, false);
                        if (resp.ok) {
                              this.getPromisesPaymentsHistorial();
                              this.alertService.openModal('Éxito', resp.message || "Promesa actualizada correctamente", 'success');
                        } else {
                              this.alertService.openModal('Error', resp.message || messageError, 'error');
                        }
                  },
                  error: (error) => {
                        toggleLoader(this.loaderService, false);
                        this.alertService.openModal('Error', error.error.message || messageError, 'error');
                        console.error(error);
                  }
            });
            this.subscriptions.add(subscription);
      }
}
