import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-loader-trasunion',
  templateUrl: './loader-trasunion.component.html',
  styleUrls: ['./loader-trasunion.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class LoaderTrasunionComponent {}
