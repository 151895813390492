import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DashboardCollectionAllCreditCustomerResponse } from '../interfaces/dashboard-collection-all-credits';
import { DashboardCustomerCollectionsResponseInterface } from '../interfaces/dashboard-collection-interface';
import { DashboardCollectionsListManagmentResponseInterface } from '../interfaces/dashboard-collection-listManagment-interface';
import { CustomerCollectionPromisesPaymentsHistorialResponseInterface } from '../interfaces/dashboard-collection-promises-payment-historial-interface';
import { DashboardCollectionRecentlySeenInterface } from '../interfaces/dashboard-collection-recentlySeen-interface';
import { DashboardCollectionSearchResultInterface } from '../interfaces/dashboard-collection-search-result-interface';
import { DashboardCollectionTemplateWhatsAppResponseInterface } from '../interfaces/dashboard-collection-templates-whatsapp.interface';
import {
      DashboardCollectionParameterPromisesPaymentsResponseInterface,
      DashboardCollectionParameterResponseInterface,
} from '../interfaces/dashboard-collections-parameters.interface';

@Injectable({
      providedIn: 'root',
})
export class DashboardCollectionsService {
      private baseUrl: string = environment.apiUrl + '/clientes';
      private baseUrlColletions: string = environment.apiUrl + '/cobranza';

      constructor(private http: HttpClient) { }

      /**
       * Obtiene todos los medios de contacto disponibles
       * @returns Observable con la lista de medios de contacto
       */
      allMediaContact() {
            return this.http.get<any>(`${this.baseUrlColletions}/all-media-contact`);
      }

      /**
       * Crea una nueva nota para un cliente
       * @param data Datos de la nota a crear
       * @returns Observable con la respuesta del servidor
       */
      createNote(data: any) {
            return this.http.post<any>(`${this.baseUrlColletions}/notas`, { ...data });
      }

      /**
       * Elimina una etiqueta asociada a un cliente
       * @param customer_id ID del cliente
       * @param tag_id ID de la etiqueta
       * @returns Observable con la respuesta del servidor
       */
      deleteTagForCustomer(customer_id: number, tag_id: number) {
            return this.http.get<any>(`${this.baseUrlColletions}/tags/byCustomer/${customer_id}/${tag_id}`);
      }

      /**
       * Edita los medios de contacto de un cliente
       * @param data Datos de los medios de contacto a editar
       * @returns Observable con la respuesta del servidor
       */
      editMediaContactByCustomerId(data: any) {
            return this.http.post<any>(`${this.baseUrlColletions}/edit-media-contact`, data);
      }

      /**
       * Obtiene todos los créditos de un cliente
       * @param id ID del cliente
       * @param state Estado opcional de los créditos
       * @returns Observable con la lista de créditos
       */
      getAllCreditsCustomer(id: number, state?: string) {
            let url = `${this.baseUrlColletions}/cliente/creditos/${id}`;
            if (state) {
                  url += `?state=${state}`;
            }
            return this.http.get<DashboardCollectionAllCreditCustomerResponse>(url);
      }

      /**
       * Obtiene todos los pagos de un cliente
       * @param id ID del cliente
       * @returns Observable con la lista de pagos
       */
      getAllPaymentsCustomer(id: number) {
            return this.http.get<any>(`${this.baseUrlColletions}/pagos/cliente/${id}`);
      }

      /**
       * Obtiene información detallada de un cliente
       * @param id ID del cliente
       * @returns Observable con la información del cliente
       */
      getCustomerInfo(id: number) {
            return this.http.get<DashboardCustomerCollectionsResponseInterface>(
                  `${this.baseUrlColletions}/cliente/detalle/${id}`
            );
      }

      /**
       * Obtiene información detallada de una promesa de pago
       * @param noteId ID de la nota
       * @returns Observable con la información de la promesa
       */
      getDetailInfoPromisePayment(noteId: number) {
            return this.http.get<any>(
                  `${this.baseUrlColletions}/cliente/promesas-pago/detalle/${noteId}`
            );
      }

      /**
       * Obtiene el historial de gestiones de un cliente
       * @param customerId ID del cliente
       * @returns Observable con el listado de gestiones
       */
      getManagmentCustomer(
            customerId: number
      ): Observable<DashboardCollectionsListManagmentResponseInterface> {
            return this.http.get<DashboardCollectionsListManagmentResponseInterface>(
                  `${this.baseUrlColletions}/cliente/notas/${customerId}`
            );
      }

      /**
       * Obtiene los medios de contacto de un cliente específico
       * @param customer_id ID del cliente
       * @returns Observable con los medios de contacto
       */
      getMediaContactByCustomerId(customer_id: number) {
            return this.http.get<any>(`${this.baseUrlColletions}/media-contact/${customer_id}`);
      }

      /**
       * Obtiene los parámetros para promesas de pago
       * @returns Observable con los parámetros
       */
      getParametersPromisesPayments() {
            return this.http.get<DashboardCollectionParameterPromisesPaymentsResponseInterface>(
                  `${this.baseUrlColletions}/parametros/promesas-pago`
            );
      }

      /**
       * Obtiene el historial de promesas de pago de un cliente
       * @param customerId ID del cliente
       * @returns Observable con el historial de promesas
       */
      getPromisesPaymentsHistorial(customerId: number) {
            return this.http.get<CustomerCollectionPromisesPaymentsHistorialResponseInterface>(
                  `${this.baseUrlColletions}/cliente/promesas-historial/${customerId}`
            );
      }

      /**
       * Obtiene los clientes vistos recientemente
       * @returns Observable con la lista de clientes recientes
       */
      getRecentlySeen() {
            return this.http.get<DashboardCollectionRecentlySeenInterface>(
                  `${this.baseUrlColletions}/vistos-recientemente`
            );
      }

      /**
       * Obtiene las etiquetas disponibles
       * @returns Observable con la lista de etiquetas
       */
      getTags() {
            return this.http.get<DashboardCollectionParameterResponseInterface>(
                  `${this.baseUrlColletions}/tags/listado`
            );
      }

      /**
       * Obtiene las etiquetas asociadas a un cliente
       * @param customer_id ID del cliente
       * @returns Observable con las etiquetas del cliente
       */
      getTagsByCustomerId(customer_id: number) {
            return this.http.get<any>(`${this.baseUrlColletions}/tags/byCustomer/${customer_id}`);
      }

      /**
       * Obtiene las plantillas de correo electrónico
       * @returns Observable con las plantillas
       */
      getTemplatesResponseEmail() {
            return this.http.get<DashboardCollectionParameterResponseInterface>(
                  `${this.baseUrlColletions}/plantillas/email/listado`
            );
      }

      /**
       * Obtiene las plantillas de atajos
       * @returns Observable con las plantillas
       */
      getTemplatesResponseShortcuts() {
            return this.http.get<DashboardCollectionParameterResponseInterface>(
                  `${this.baseUrlColletions}/plantillas/atajos/listado/`
            );
      }

      /**
       * Obtiene las plantillas de WhatsApp
       * @returns Observable con las plantillas
       */
      getTemplatesResponseWhatsApp() {
            return this.http.get<DashboardCollectionTemplateWhatsAppResponseInterface>(
                  `${this.baseUrlColletions}/plantillas/whatsapp/listado`
            );
      }

      /**
       * Obtiene los tipos de contacto disponibles
       * @returns Observable con los tipos de contacto
       */
      getTypesContact() {
            return this.http.get<DashboardCollectionParameterPromisesPaymentsResponseInterface>(
                  `${this.baseUrlColletions}/tipos-contacto`
            );
      }

      /**
       * Busca clientes según un valor de búsqueda
       * @param valueSearch Texto de búsqueda
       * @returns Observable con los resultados de la búsqueda
       */
      searchCustomer(valueSearch: string) {
            return this.http.get<DashboardCollectionSearchResultInterface>(
                  `${this.baseUrlColletions}/cliente/buscar/${valueSearch}/`
            );
      }

      /**
       * Actualiza la información de un cliente
       * @param data Datos actualizados del cliente
       * @param customerId ID del cliente
       * @returns Observable con la respuesta del servidor
       */
      updateCustomer(data: any, customerId: number) {
            return this.http.put<any>(
                  `${this.baseUrlColletions}/cliente-actualizar/${customerId}/`, data
            );
      }

      /**
       * Actualiza el estado de favorito de un cliente
       * @param customerId ID del cliente
       * @param favorite Estado de favorito
       * @returns Observable con la respuesta del servidor
       */
      updateFavorite(customerId: number, favorite: boolean) {
            return this.http.put<any>(`${this.baseUrl}/actualizar-favorito/`, {
                  customerId: customerId,
                  favorite: favorite,
            });
      }

      /**
       * Actualiza una promesa de pago
       * @param promiseId ID de la promesa
       * @param formValues Valores actualizados del formulario
       * @returns Observable con la respuesta del servidor
       */
      updatePromisePayment(promiseId: number, formValues: any) {
            return this.http.put<any>(
                  `${this.baseUrlColletions}/cliente/promesa/actualizar/${promiseId}`,
                  formValues
            );
      }

      /**
       * Actualiza el cumplimiento de una promesa de pago
       * @param promisePaymentId ID de la promesa
       * @param compliment Estado de cumplimiento
       * @param reason Razón del cumplimiento
       * @returns Observable con la respuesta del servidor
       */
      updatePromisePaymentCompliment(
            promisePaymentId: number,
            compliment: string,
            reason: string
      ) {
            return this.http.put<any>(
                  `${this.baseUrlColletions}/cliente/promesas/cumplimiento/${promisePaymentId}`,
                  { compliment: compliment, reason: reason }
            );
      }
}
