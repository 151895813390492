<div class="bl-search-results">
      <div class="bl-search-results--title">
            <strong><i class="fas fa-search ps-2 pe-2"></i>Resultados de la busqueda</strong>
      </div>
      @if (resultSearchCustomer.length === 0) {
      <p class="text-center">Realiza una busqueda.</p>
      }
      @for (resultSearchCustomerItem of resultSearchCustomer; track resultSearchCustomerItem) {
      <div class="bl-search-results--contenido--item">
            <div class="bl-search-results--contenido--item--icon">
                  <span>{{getInitials(resultSearchCustomerItem.name,resultSearchCustomerItem.last_name)}}</span>
            </div>
            <div class="bl-search-results--contenido--item--text"
                  (click)="redirectDetalle(resultSearchCustomerItem.id)">
                  <span class="fw-semibold text-uppercase">{{resultSearchCustomerItem.full_name}}</span>
                  <br />
                  <span class="fw-semibold">Cedula:</span>
                  <span>{{resultSearchCustomerItem.identification}}</span>
                  <span class="fw-semibold">Cel:</span>
                  <span>{{resultSearchCustomerItem.phone}}</span>
                  <br />
                  <span class="fw-semibold">Monto Aprobado:</span>
                  <span>{{resultSearchCustomerItem.total_approved_quota}}</span>
                  <span class="fw-semibold">Monto Disponible:</span>
                  <span>{{resultSearchCustomerItem.total_approved_quota}}</span>
            </div>
      </div>
      }
      <div class="bl-search-results--title">
            <strong><i class="fas fa-eye ps-2 pe-2"></i>Clientes Recientes</strong>
      </div>
      <app-recently-seen></app-recently-seen>
</div>
