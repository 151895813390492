<h2 class="accordion-header" id="flush-headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
            <i class="fas fa-hand-holding-usd"></i>&nbsp;Créditos pendientes
      </button>
</h2>
<div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
      data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
            <div class="bl-listadoConversaciones--informacion-list">
                  <div class="row">
                        <div class="row container mb-3">
                              <small class="mb-1">Puedes buscar por estado dando clic en los botones</small>
                              <div class="col filter">
                                    <button class="badge badge-primary" [class.selected]="selectedState === ''"
                                          (click)="getCredits('')">Todos</button>
                                    <button class="badge badge-danger"
                                          [class.selected]="selectedState === 'pendiente_entrega'"
                                          (click)="getCredits('pendiente_entrega')">Pendiente entrega</button>
                                    <button class="badge badge-warning"
                                          [class.selected]="selectedState === 'pendiente_pago'"
                                          (click)="getCredits('pendiente_pago')">Pendiente pago</button>
                                    <button class="badge badge-success" [class.selected]="selectedState === 'pagado'"
                                          (click)="getCredits('pagado')">Pagado</button>
                                    <button class="badge" style="background-color: #064853; color: #fff !important;"
                                          [class.selected]="selectedState === 'en_mora'"
                                          (click)="getCredits('en_mora')">En mora</button>
                              </div>
                        </div>
                        @if (credits.length > 0) {
                        @for (credit of credits; track credit) {
                        <div class="col-12">
                              <div class="card">
                                    <div class="card-body">
                                          <h5 class="card-text mb-1">
                                                <strong>Número de Obligación:</strong> {{ credit.code_pay }}
                                          </h5>
                                          <p class="card-text mb-1">
                                                <strong>Número de Orden:</strong> {{ credit.order_number |
                                                defaultIfEmpty:'Número de orden no disponible' }}
                                          </p>
                                          <p class="card-text mb-1">
                                                <strong>Fecha de Entrega:</strong> {{ credit.delivery_date }}
                                          </p>
                                          <p class="card-text mb-1">
                                                <strong>Fecha Límite:</strong> {{ credit.deadline }}
                                          </p>
                                          <p class="card-text mb-1">
                                                <strong>Valor Pendiente:</strong> {{ credit.value_pending | currency :
                                                '$' : 'symbol' : '1.0-0' }}
                                          </p>
                                          <p class="card-text mb-1">
                                                <strong>Días Mora:</strong> {{ credit.days_late_credit }}
                                          </p>
                                          <p class="card-text mb-1">
                                                <strong>Valor Intereses:</strong> {{ credit.interest_value_calculate |
                                                currency : '$' : 'symbol' : '1.0-0' }}
                                          </p>
                                          <p class="card-text mb-1">
                                                <strong>Días Corrientes:</strong> {{ credit.credit_payment_term_days }}
                                          </p>
                                          <p class="card-text mb-1">
                                                <strong>Valor Corriente:</strong> {{ credit.current_value_calculate |
                                                currency : '$' : 'symbol' : '1.0-0' }}
                                          </p>
                                          <p class="card-text mb-1">
                                                <strong>Saldo Total:</strong> {{ credit.total_balance | currency : '$' :
                                                'symbol' : '1.0-0' }}
                                          </p>
                                          <p class="card-text mb-1">
                                                <strong>Estado:&nbsp;</strong>
                                                <span class="badge badge-{{ credit.credits_states_color }}">{{
                                                      credit.credits_states_name }}</span>
                                          </p>
                                    </div>
                              </div>
                        </div>
                        }
                        } @else {
                        <div class="col-12">
                              @if (loading) {
                              <p>Cargando...</p>
                              } @else {
                              <p>No hay créditos disponibles.</p>
                              }
                        </div>
                        }
                  </div>
            </div>
      </div>
</div>
