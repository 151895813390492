@if(customers.customer_late || customers.promisePayToday || customers.creditsToPayToday) {
<div class="col-12 alerts-title">
      Alertas
</div>
}
<div class="bl-customer-alerts alerts-next">
      @if(customers.customer_late) {
      <div class="bl-alert-item bl-alert-mora col-12 m-0">
            <i class="fas fa-exclamation-circle"></i>
            <span>&nbsp;Cliente está en mora.</span>
      </div>
      }
      @if(customers.promisePayToday) {
      <div class="bl-alert-item bl-alert-promesa col-12 m-0">
            <i class="fas fa-calendar-alt"></i>
            <span>&nbsp;Tiene una promesa de pago para hoy.</span>
      </div>
      }
      @if(customers.creditsToPayToday) {
      <div class="bl-alert-item bl-alert-creditos col-12 m-0">
            <i class="fas fa-chart-line"></i>
            <span>&nbsp;Tiene créditos para pagar hoy.</span>
      </div>
      }
</div>
@if(dataInfo.length > 0) {
<div class="col-12 alerts-title">
      Notas del cliente
</div>
}
<div class="d-flex flex-wrap">
      @for(info of dataInfo; track $index) {
      <div class="bl-alert-item bl-alert-credito m-0 text-light contenido-div"
            [style.background-color]="info.get_tag.color">
            <i class="fas fa-exclamation-circle"></i>
            <span class="text-light">&nbsp;Cliente con etiqueta {{ info.get_tag.name }}</span>
            <span (click)="selectedTag = info" data-bs-toggle="modal" data-bs-target="#exampleModalNotification"
                  class="button-close"> &times; </span>
      </div>
      }
</div>

<!-- Modal -->
<div class="modal fade modal-notification" id="exampleModalNotification" tabindex="-1"
      aria-labelledby="exampleModalNotificationLabel" aria-hidden="true">
      <div class="modal-dialog">
            @if(selectedTag) {
            <div class="modal-content">
                  <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalNotificationLabel">¿Eliminar Etiqueta?</h5>
                        <button (click)="selectedTag=null" type="button" class="btn-close" data-bs-dismiss="modal"
                              aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                        ¿Estas seguro de que deseas eliminar la etiqueta {{ selectedTag.get_tag.name }} del cliente {{
                        customers.full_name }} ?
                  </div>
                  <div class="modal-footer">
                        <button (click)="selectedTag=null" type="button" class="btn btn-secondary"
                              data-bs-dismiss="modal">Cerrar</button>
                        <button (click)="deleteTagForCustomer(selectedTag.get_tag.id)" type="button"
                              class="btn btn-primary">Eliminar</button>
                  </div>
            </div>
            }
      </div>
</div>
