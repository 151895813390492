<div class="modal-header">
  <h4 class="modal-title">Información Financiera</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <div>
    
  
    <div *ngIf="!isLoading && !isString && scoreTable.length && obligacionesTotales.length">
      <app-table-trasunion [scoreTable]="scoreTable" [obligacionesT]="obligacionesTotales" ></app-table-trasunion>
    </div>
    
    <div class="d-flex justify-content-center align-items-center" *ngIf="isLoading">
      <app-loader-trasunion></app-loader-trasunion>
    </div>

    <div *ngIf="!isLoading && isString">
      <h5>No hay información TransUnion <i class="fa-solid fa-circle-exclamation"></i> </h5> 
    </div>

    <div *ngIf="!isLoading && isNullRue">
      <br>
      <h5>No hay información Rues <i class="fa-solid fa-circle-exclamation"></i> </h5> 
    </div>


    <div *ngIf="!isLoading && !isNullRue" >
      <app-table-rues [infoRues]="ruesTable"></app-table-rues>
    </div>




  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Cerrar</button>
</div>
