<h2 class="accordion-header" id="flush-headingSix">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
            <i class="fas fa-project-diagram"></i>&nbsp; Total de gestiones
      </button>
</h2>
<div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix"
      data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
            <div class="bl-listadoConversaciones--informacion-list">
                  @if (totalMaganamentCustomer) {
                  <div class="row mb-3 card-border">
                        <!-- Badge para total de respuestas -->
                        <div class="col-3 text-center">
                              <span class="badge badge-primary">
                                    <i class="fas fa-reply"></i> {{ totalMaganamentCustomer.totalAnswer }}
                              </span>
                              <p>Total Respuestas</p>
                        </div>

                        <!-- Badge para total de llamadas -->
                        <div class="col-3 text-center">
                              <span class="badge badge-success">
                                    <i class="fas fa-phone-alt"></i> {{ totalMaganamentCustomer.totalCalls }}
                              </span>
                              <p>Total Llamadas</p>
                        </div>

                        <!-- Badge para total de emails -->
                        <div class="col-3 text-center">
                              <span class="badge badge-info">
                                    <i class="fas fa-envelope"></i> {{ totalMaganamentCustomer.totalEmails }}
                              </span>
                              <p>Total Emails</p>
                        </div>

                        <!-- Badge para total de notas -->
                        <div class="col-3 text-center">
                              <span class="badge badge-warning">
                                    <i class="fas fa-sticky-note"></i> {{ totalMaganamentCustomer.totalNote }}
                              </span>
                              <p>Total Notas</p>
                        </div>

                        <!-- Badge para total de gestiones -->
                        <div class="col-12 text-center mt-3">
                              <span class="badge badge-danger">
                                    <i class="fas fa-tasks"></i> {{ totalMaganamentCustomer.total }}
                              </span>
                              <p>Total Gestiones</p>
                        </div>
                  </div>
                  }
            </div>
      </div>
</div>
