<div class="bl-vistoReciente--contenido">
      @if (customersRecentlySeen.length > 0) {
      @for (customersRecentlySeenItem of customersRecentlySeen; track customersRecentlySeenItem.id) {
      <div class="bl-vistoReciente--contenido--item">
            <div class="bl-vistoReciente--contenido--item--icon"
                  (click)="redirectDetalle(customersRecentlySeenItem.id)">
                  <span>{{
                        getInitials(
                        customersRecentlySeenItem.name,
                        customersRecentlySeenItem.last_name
                        )
                        }}</span>
            </div>
            <div class="bl-vistoReciente--contenido--item--text"
                  (click)="redirectDetalle(customersRecentlySeenItem.id)">
                  {{
                  customersRecentlySeenItem.name +
                  ' ' +
                  customersRecentlySeenItem.last_name
                  }}
            </div>
      </div>
      }
      } @else {
      <ng-template #sinCustomerRecentlySeen>
            <div>
                  <p class="text-center">Aun no hay información para mostrar</p>
            </div>
      </ng-template>
      }
</div>
