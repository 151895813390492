import { getInitials, toggleLoader } from '../../../../shared/helpers/functions-helper.service';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoaderService } from '../../../../shared/services/loader.service';

import {
      CustomerCollectionInterface,
      CustomerCollectionPaymentCustomerHistorial,
      CustomerCollectionPromisesPaymentsHistorial,
      CustomerCollectionTotalManagement,
} from '../../interfaces/dashboard-collection-interface';
import { recentlySeenCollectionInterface } from '../../interfaces/dashboard-collection-recentlySeen-interface';

import { DashboardCollectionsService } from '../../services/dashboard-collections.service';
import { DashboardComunicationComponentService } from '../../services/dashboardComunicationsComponente.service';

import { BreadcrumbComponent } from '../../../../shared/components/breadcrumb/breadcrumb.component';
import { AlertsTodayCustomerComponent } from '../../components/alerts-today-customer/alerts-today-customer.component';
import { ModalServiceAlert } from '../../../../shared/components/modal-alert/service/modal-alert.service';
import { CommonModule } from '@angular/common';
import { CustomerInformationComponent } from '../../components/customer-information/customer-information.component';
import { SearchCustomerComponent } from '../../components/search-customer/search-customer.component';
import { DashboardCollectionsSearchResultsComponent } from '../../components/dashboard-collections-search-results/dashboard-collections-search-results.component';
import { DetailCustomerComponent } from '../../components/detail-customer/detail-customer.component';
import { ModalFinantialComponent } from '../../../../shared/components/modals/modal-finantial/modal-finantial.component';
import { FinalcialInformationComponent } from '../../components/finalcial-information/finalcial-information.component';
import { PromisesPaymentHistoryCustomerComponent } from '../../components/promises-payment-history-customer/promises-payment-history-customer.component';
import { PaymentsHistorialCustomerComponent } from '../../components/payments-historial-customer/payments-historial-customer.component';
import { TotalManagementCustomerComponent } from '../../components/total-management-customer/total-management-customer.component';
import { CreditsAllCustomerComponent } from '../../components/credits-all-customer/credits-all-customer.component';
import { DashboardCollectionsPaymentTotalsComponent } from '../../components/dashboard-collections-payment-totals/dashboard-collections-payment-totals.component';
import { RecentlySeenComponent } from '../../components/recently-seen/recently-seen.component';

@Component({
      selector: 'app-dashboard-collections',
      templateUrl: './dashboard-collections.component.html',
      styleUrls: ['./dashboard-collections.component.scss'],
      imports: [
            CommonModule,
            BreadcrumbComponent,
            AlertsTodayCustomerComponent,
            CustomerInformationComponent,
            SearchCustomerComponent,
            DashboardCollectionsSearchResultsComponent,
            DetailCustomerComponent,
            ModalFinantialComponent,
            FinalcialInformationComponent,
            PromisesPaymentHistoryCustomerComponent,
            PaymentsHistorialCustomerComponent,
            TotalManagementCustomerComponent,
            CreditsAllCustomerComponent,
            DashboardCollectionsPaymentTotalsComponent,
            RecentlySeenComponent
      ],
      standalone: true
})
export class DashboardCollectionsComponent {
      customers!: CustomerCollectionInterface;
      paymentsHistorialCustomer!: CustomerCollectionPaymentCustomerHistorial[];
      customersRecentlySeen!: recentlySeenCollectionInterface[];
      promisesPaymentsHistorial!: CustomerCollectionPromisesPaymentsHistorial[];
      totalMaganamentCustomer!: CustomerCollectionTotalManagement;
      showSearchResults!: boolean;
      customerId: number = NaN;
      @Input() customer_id: number | null = null;
      suscriptionNewReloadService!: Subscription;

      breadcrumbItems = [
            {
                  label: 'Cartera clientes',
                  url: '/cobranza/cartera-clientes',
            },
            {
                  label: 'Gestión del cliente',
                  url: '/cobranza/inicio/',
            },
      ];

      getInitials = getInitials;

      constructor(
            private dashboardCollectionsService: DashboardCollectionsService,
            private loaderService: LoaderService,
            private comunicationCollectionServices: DashboardComunicationComponentService,
            private route: ActivatedRoute,
            private ref: ChangeDetectorRef,
            private alerService: ModalServiceAlert
      ) { }

      /**
       * Inicializa el componente y configura las suscripciones necesarias
       */
      ngOnInit(): void {
            this.getIdurl();
            toggleLoader(this.loaderService, true);

            let searchResultSubscription = this.comunicationCollectionServices.visibleSearchResult.subscribe(
                  (value) => {
                        this.showSearchResults = value;
                  }
            );

            let customerIdSubscription = this.comunicationCollectionServices.customerId.subscribe((value) => {
                  this.customerId = parseInt(value);
                  if (!isNaN(this.customerId)) {
                        this.getCustomerInfo();
                  }
            });

            Promise.all([searchResultSubscription, customerIdSubscription]).then(() => {
                  toggleLoader(this.loaderService, false);
            }).catch(() => {
                  toggleLoader(this.loaderService, false);
            });
      }

      /**
       * Obtiene el ID del cliente de la URL o del input
       */
      getIdurl() {
            this.route.paramMap.subscribe((params) => {
                  if (this.customer_id != null) {
                        this.customerId = this.customer_id;
                        this.comunicationCollectionServices.setCustomer_Id(this.customer_id);
                        this.getCustomerInfo();
                  } else {
                        const id = params.get('id');
                        if (id) {
                              this.customerId = parseInt(id);
                              if (!isNaN(this.customerId)) {
                                    this.getCustomerInfo();
                              }
                        }
                  }
            });
      }

      /**
       * Obtiene la información detallada del cliente
       */
      getCustomerInfo() {
            toggleLoader(this.loaderService, true);
            this.dashboardCollectionsService.getCustomerInfo(this.customerId).subscribe(
                  (resp) => {
                        toggleLoader(this.loaderService, false);
                        this.ref.detectChanges();
                        if (resp.ok) {
                              this.customers = resp.data.customer;
                              this.comunicationCollectionServices.setInfoCustomer(resp.data.customer);
                              this.paymentsHistorialCustomer = resp.data.payments_historial;
                              this.totalMaganamentCustomer = resp.data.total_maganament;
                              this.comunicationCollectionServices.setCustomerId(String(this.customerId));
                        } else {
                              this.alerService.openModal("Error", "Hubo un error al realizar la consulta", "error");
                        }
                  },
                  (error) => {
                        toggleLoader(this.loaderService, false);
                        this.alerService.openModal("Error", "Hubo un error en la solicitud", "error");
                  }
            );
      }

      /**
       * Actualiza el estado de favorito de un cliente
       * @param customerId ID del cliente
       * @param favorite Estado de favorito a establecer
       */
      setToggleFavorite(customerId: number, favorite: boolean) {
            toggleLoader(this.loaderService, true);
            this.dashboardCollectionsService.updateFavorite(customerId, favorite).subscribe(
                  (resp) => {
                        toggleLoader(this.loaderService, false);
                        if (resp.ok) {
                              this.getCustomerInfo();
                        } else {
                              this.alerService.openModal("Error", "Hubo un error al marcar el cliente como favorito", "error");
                        }
                  },
                  (error) => {
                        toggleLoader(this.loaderService, false);
                        this.alerService.openModal("Error", "Hubo un error en la solicitud", "error");
                  }
            );
      }
}
