<h2 class="accordion-header" id="flush-headingFive">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
            <i class="fas fa-handshake"></i>&nbsp; Historial promesas de pago
      </button>
</h2>
<div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive"
      data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
            <div class="bl-listadoConversaciones--informacion-list">
                  @if (promisesPaymentsHistorial) {
                  <div>
                        @for (promise of promisesPaymentsHistorial; track promise.id) {
                        <div class="row mb-3 card-border">
                              <div class="col-6">
                                    <i class="fas fa-calendar-alt"></i> {{ promise.date_payment }}
                              </div>
                              <div class="col-12">
                                    <i class="fas fa-coins"></i> Método de Pago: {{
                                    promise.collections_payments_means_name }}
                              </div>
                              <div class="col-12">
                                    <i class="fas fa-comments"></i> Tipo de Contacto: {{
                                    promise.collections_types_contacts_name }}
                              </div>
                              <div class="col-12">
                                    <i class="fas fa-exclamation-triangle"></i> Motivo de Mora: {{
                                    promise.collections_motives_mora_name }}
                              </div>
                              <div class="col-12">
                                    <i class="fas fa-keyboard"></i> Nota:&nbsp; <span
                                          [innerHTML]="promise.collections_notes_text"></span>
                              </div>

                              <ng-template #NoResponse>
                                    <span [ngClass]="{'badge badge-success': promise.compliment === 'Si', 'badge badge-danger': promise.compliment !== 'Si'}"
                                          class="text-white text-center">
                                          Cumplida : {{ promise.compliment }}
                                    </span>
                              </ng-template>


                              <!-- Footer adicional en la misma fila -->
                              @if (promise.is_for_today) {
                              <div class="col-12 text-center text-white bg-danger">
                                    <strong>HOY</strong>
                              </div>
                              }
                        </div>
                        }
                  </div>
                  } @else {
                  <div class="col-12 mt-2 text-center mb-2">
                        No hay registros
                  </div>
                  }
            </div>
      </div>
</div>
